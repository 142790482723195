import image from 'shared/assets/images/Seagull.png';
import { IDimensions } from 'types';
import useCreateEnemy from '../../../hooks/useCreateEnemy';
import getRandomInt from 'app/Game/functions/getRandomInt';

const Seagull = (dimensions: IDimensions, startX: number) => {
  const imageProperties = {
    width: 105 * dimensions.coef, // 131
    height: 68 * dimensions.coef, // 85
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 85 * dimensions.coef, // 100
      height: 9 * dimensions.coef, // 10
      left: x,
      top: y + 52 * dimensions.coef, // 65
    },
  ];

  return useCreateEnemy({
    imageProperties,
    collisionBlocks,
    image,
    dimensions,
    startX,
    getRandomY: () => getRandomInt(dimensions.height / 2 - imageProperties.height),
  });
};

export default Seagull;
