import { ReactNode } from 'react';
import cn from 'classnames';
import './Button.scss';

interface ButtonProps {
  children: ReactNode;
  size?: 'medium' | 'large';
  onClick?: () => void;
  type?: 'button' | 'submit';
  className?: string;
  disabled?: boolean;
}

export const Button = ({
  children,
  size = 'medium',
  type = 'button',
  onClick,
  className,
  disabled,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={cn('Button', { Button__large: size === 'large' }, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
