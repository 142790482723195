import { CSSProperties, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Modal.scss';

const Modal = ({
  children,
  isOpen,
  style,
}: {
  children: ReactNode;
  isOpen: boolean;
  style?: CSSProperties;
}) => {
  return (
    <CSSTransition in={isOpen} timeout={200} classNames="Modal" unmountOnExit>
      <div className="Modal__backdrop">
        <div className="Modal__wrapper">
          <div className="Modal__content" style={style} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
