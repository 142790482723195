import image from 'shared/assets/images/Building4.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building4 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 146 * dimensions.coef,
    height: 216 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: imageProperties.width,
      height: 80 * dimensions.coef,
      left: x,
      top: y + 136 * dimensions.coef,
    },
    {
      width: 120 * dimensions.coef,
      height: 40 * dimensions.coef,
      left: x + 13 * dimensions.coef,
      top: y + 96 * dimensions.coef,
    },
    {
      width: 30 * dimensions.coef,
      height: 86 * dimensions.coef,
      left: x + 80 * dimensions.coef,
      top: y + 10 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building4;
