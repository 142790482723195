import ManyPoints from 'app/components/CrashScreen/ManyPoints';
import ZeroPoints from 'app/components/CrashScreen/ZeroPoints';
import { createPortal } from 'react-dom';
import Modal from 'shared/ui/Modal';
import useGlobalStore from 'useGlobalStore';

const ModalLayer = () => {
  const isPlaying = useGlobalStore((state) => state.isPlaying);
  const crashedInto = useGlobalStore((state) => state.crashedInto);
  const pointsCount = useGlobalStore((state) => state.pointsCount);
  const { screenSize } = useGlobalStore((state) => state.dimensions);

  const isManyPoints = !isPlaying && pointsCount > 0;
  const isZeroPoints = !isPlaying && pointsCount === 0 && !!crashedInto;

  return createPortal(
    <Modal
      isOpen={isManyPoints || isZeroPoints}
      style={screenSize === 'mobile' && isManyPoints ? { marginBottom: '75px' } : undefined}
    >
      {isManyPoints ? <ManyPoints /> : null}
      {isZeroPoints ? <ZeroPoints /> : null}
    </Modal>,
    document.getElementById('modal')!
  );
};

export default ModalLayer;
