import { Stage } from '@pixi/react';
import Game from './Game';
import useGlobalStore from 'useGlobalStore';

const GameWrapper = () => {
  const dimensions = useGlobalStore((state) => state.dimensions);

  return (
    <Stage
      width={dimensions.width}
      height={dimensions.height}
      options={{ backgroundAlpha: 0, antialias: true }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'linear-gradient(180deg, #4557ff 0%, #501da3 100%)',
      }}
    >
      <Game dimensions={dimensions} />
    </Stage>
  );
};

export default GameWrapper;
