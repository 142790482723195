import { useState } from 'react';
import { Sprite } from '@pixi/react';
import image from 'shared/assets/images/Protagonist.png';
import { IDimensions } from 'types';
import useGameObj from '../../hooks/useGameObj';

const posibleX = {
  desktop: 60,
  tablet: 40,
  mobile: 20,
};

const possibleDelta = {
  desktop: 4,
  tablet: 4.5,
  mobile: 5,
};

const Protagonist = (dimensions: IDimensions) => {
  const x = posibleX[dimensions.screenSize];
  const delta = possibleDelta[dimensions.screenSize];
  const coef = dimensions.screenSize === 'mobile' ? dimensions.coef * 0.8 : dimensions.coef;

  const imageProperties = {
    width: 236 * coef, // 295
    height: 108 * coef, // 135
  };
  const [yBeforeSpacePressed, setYBeforeSpacePressed] = useState(
    (dimensions.height - imageProperties.height) / 2
  );

  const properties = useGameObj({
    x,
    y: dimensions.height / 2,
  });

  const collisionBlock = () => ({
    width: 212 * coef, // 265
    height: 64 * coef, // 80
    left: x + 12 * coef, // 15
    top: properties.y + 24 * coef, // 30
  });

  const calcNewY = (timeSinceSpacePressed: number, prevStartingPoint: number) => {
    const newY = -(
      100 * coef -
      (timeSinceSpacePressed * delta * coef - Math.sqrt(100 * coef)) ** 2 -
      prevStartingPoint
    );

    // процент разницы между новым и предыдущем значением относительно высоты игрового пространства
    // const changePercent = Math.round(
    //   (Math.abs(newY - properties.y) / (dimensions.height - imageProperties.height)) * 100
    // );

    // if (changePercent > 3) return properties.y;

    return newY;
  };

  const updateY = (timeSinceSpacePressed: number | undefined) => {
    const prevStartingPoint = timeSinceSpacePressed ? undefined : properties.y;
    const newY = calcNewY(timeSinceSpacePressed || 0.1, prevStartingPoint || yBeforeSpacePressed);
    prevStartingPoint && setYBeforeSpacePressed(prevStartingPoint);
    properties.setY(newY);
  };

  return {
    ...imageProperties,
    ...properties,
    component: () => <Sprite image={image} x={x} y={properties.y} {...imageProperties} />,
    collisionBlock,
    updateY,
  };
};

export default Protagonist;
