import { ReactNode } from 'react';
import './ResolutionChangedScreen.scss';

const ResolutionChangedScreen = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: ReactNode | string;
}) => {
  if (!isOpen) return null;

  return (
    <div className="ResolutionChangedScreen">
      <div className="ResolutionChangedScreen__roll">{children}</div>
    </div>
  );
};

export default ResolutionChangedScreen;
