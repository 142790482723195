import { useState } from 'react';

const useGameObj = (defaultValues: { x: number; y: number }) => {
  const [x, setX] = useState(defaultValues.x);
  const [y, setY] = useState(defaultValues.y);
  const [yAxis, setYAxis] = useState(defaultValues.y);

  return { x, setX, y, setY, yAxis, setYAxis };
};

export default useGameObj;
