import prand from 'pure-rand';

const seed = new Date().getMilliseconds();
const rngSimulation = prand.xoroshiro128plus(seed);

function getRandomInt(minOrMax: number, maxOptional?: number) {
  const min = maxOptional ? minOrMax : 0;
  const max = maxOptional ? maxOptional : minOrMax;
  return prand.unsafeUniformIntDistribution(min, max, rngSimulation);
}

export default getRandomInt;
