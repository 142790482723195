import { Routes, Route, Outlet } from 'react-router-dom';
import GameLayer from 'app/Layers/GameLayer';
import ModalLayer from 'app/Layers/ModalLayer';
import MusicAndRulesLayer from 'app/Layers/MusicAndRulesLayer';
import ResolutionChangedAndLoadingLayer from 'app/Layers/ResolutionChangedAndLoadingLayer';

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<GameLayer />} />
      </Route>
    </Routes>
  );
};

function Layout() {
  return (
    <>
      <Outlet />
      <ModalLayer />
      <MusicAndRulesLayer />
      <ResolutionChangedAndLoadingLayer />
    </>
  );
}
