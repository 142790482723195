import { Sprite } from '@pixi/react';
import { IDimensions } from 'types';
import useGameObj from '../hooks/useGameObj';
import checkCollision, { TCollisionObject } from '../functions/checkCollision';
import sineWave from '../functions/sineWave';

const useCreateEnemy = ({
  imageProperties,
  collisionBlocks,
  image,
  dimensions,
  startX,
  getRandomY,
}: {
  imageProperties: {
    width: number;
    height: number;
  };
  collisionBlocks: ({ x, y }: { x: number; y: number }) => {
    width: number;
    height: number;
    left: number;
    top: number;
  }[];
  image: string;
  dimensions: IDimensions;
  startX: number;
  getRandomY: () => number;
}) => {
  const properties = {
    ...useGameObj({
      x: startX,
      y: getRandomY(),
    }),
    ...imageProperties,
  };

  const update = (
    delta: number,
    protagonistCollision: TCollisionObject,
    speedMultiplier: number,
    leftBoundary: number
  ) => {
    const newX =
      properties.x > -properties.width
        ? properties.x - delta * speedMultiplier
        : leftBoundary + 200 * dimensions.coef;

    properties.setX(newX);
    const newYAxis = properties.x > -properties.width ? undefined : getRandomY();
    if (newYAxis) properties.setYAxis(newYAxis);
    const newY = sineWave(newX, newYAxis || properties.yAxis, dimensions.coef);
    properties.setY(newY);

    const isCollide = collisionBlocks({ x: newX, y: newY })
      .map((col) => checkCollision(protagonistCollision, col))
      .includes(true);

    return { isCollide };
  };

  const reset = () => {
    properties.setYAxis(getRandomY());
    properties.setX(startX);
  };

  return {
    component: () => (
      <Sprite image={image} x={properties.x} y={properties.y} {...imageProperties} />
    ),
    update,
    reset,
    x: properties.x,
  };
};

export default useCreateEnemy;
