import { Sprite } from '@pixi/react';
import useGameObj from '../hooks/useGameObj';
import checkCollision, { TCollisionObject } from '../functions/checkCollision';
import { useEffect } from 'react';
import { IDimensions } from 'types';
import { ETALON_WINDOW_WIDTH } from 'consts';

const CYCLE_WIDTH = 5150;

const useCreateBuilding = (
  imageProperties: {
    width: number;
    height: number;
  },
  collisionBlocks: ({ x, y }: { x: number; y: number }) => {
    width: number;
    height: number;
    left: number;
    top: number;
  }[],
  image: string,
  dimensions: IDimensions,
  xShift: number
) => {
  const distance = dimensions.width < ETALON_WINDOW_WIDTH ? ETALON_WINDOW_WIDTH : dimensions.width;
  const calculateX = () => xShift * dimensions.coef;

  const properties = {
    ...useGameObj({
      x: calculateX() + distance,
      y: dimensions.height - imageProperties.height,
    }),
    ...imageProperties,
  };

  useEffect(() => {
    properties.setY(dimensions.height - imageProperties.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  const updateX = (
    delta: number,
    protagonistCollision: TCollisionObject,
    speedMultiplier: number
  ) => {
    const newX =
      properties.x > -properties.width
        ? properties.x - delta * speedMultiplier
        : CYCLE_WIDTH * dimensions.coef - properties.width;
    properties.setX(newX);
    const isCollide = collisionBlocks({ x: newX, y: properties.y })
      .map((col) => checkCollision(protagonistCollision, col))
      .includes(true);
    return { isCollide };
  };

  const resetX = () => properties.setX(calculateX() + distance);

  return {
    component: () => (
      <Sprite image={image} x={properties.x} y={properties.y} {...imageProperties} />
    ),
    properties,
    updateX,
    resetX,
  };
};

export default useCreateBuilding;
