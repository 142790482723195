import image from 'shared/assets/images/Newspaper.png';
import { IDimensions } from 'types';
import useCreateEnemy from '../../../hooks/useCreateEnemy';
import getRandomInt from 'app/Game/functions/getRandomInt';

const Newspaper = (dimensions: IDimensions, startX: number) => {
  const imageProperties = {
    width: 100 * dimensions.coef, // 126
    height: 78 * dimensions.coef, // 98
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 40 * dimensions.coef, // 50
      height: 52 * dimensions.coef, // 65
      left: x + 18 * dimensions.coef, // 23
      top: y + 24 * dimensions.coef, // 30
    },
    {
      width: 40 * dimensions.coef, // 50
      height: 36 * dimensions.coef, // 45
      left: x + 58 * dimensions.coef, // 73
      top: y + 24 * dimensions.coef, // 30
    },
  ];

  return useCreateEnemy({
    imageProperties,
    collisionBlocks,
    image,
    dimensions,
    startX,
    getRandomY: () =>
      getRandomInt(dimensions.height / 2, dimensions.height - imageProperties.height),
  });
};

export default Newspaper;
