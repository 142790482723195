import { Button } from 'shared/ui/Button/Button';
import gifts from 'shared/assets/images/gifts.png';
import './Form.scss';
import { useForm } from 'react-hook-form';
import useGlobalStore from 'useGlobalStore';
import getNoun from 'shared/functions/getNoun';
import cn from 'classnames';
import exit_image from 'shared/assets/images/exit.svg';
import BarLoader from 'react-spinners/BarLoader';
import { YMClose, YMPersonnalData, YMSale } from 'shared/api/YandexMetrikaFunctions';

export type TInputs = {
  name: string;
  email: string;
  personal_data: boolean;
  commercials: boolean;
};

const Form = ({ onSubmit, disabled }: { onSubmit: (_: TInputs) => void; disabled: boolean }) => {
  const pointsCount = useGlobalStore((state) => state.pointsCount);
  const startPlaying = useGlobalStore((state) => state.startPlaying);
  const calcDimensions = useGlobalStore((state) => state.calcDimensions);
  const promocodeAmount = useGlobalStore((state) => state.promocodeAmount);


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TInputs>();

  const replay = () => {
    YMClose();
    startPlaying();
    calcDimensions();
  };

  return (
    <>
      <button type="button" className="Form__close" onClick={replay}>
        <img src={exit_image} alt="кнопка закрытия модального окна" />
      </button>
      <div className="Form__postcard">
        <p className="fs-title Form__postcard__title">Наши поздравления!</p>
        <p className="fs-subtitle Form__postcard__subtitle">
          Вы заработали {pointsCount} {getNoun(pointsCount, 'балл', 'балла', 'баллов')}
        </p>
        <p className="fs-main Form__postcard__text">
          и выиграли промокод со скидкой {promocodeAmount}% на покупку билетов
        </p>
        <img src={gifts} alt="изображение подарков" className="Form__postcard__image" />
      </div>
      <p className="fs-subtitle">Оставьте email, чтобы получить подарок</p>
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="Form__form">
        <input
          placeholder="Имя"
          className={cn('Input', { Input__error: errors.name })}
          disabled={disabled}
          {...register('name', { required: true })}
        />
        <span className="fs-secondary Input__error_subtitle">
          {errors.name && errors.name.type === 'required' ? 'Введите имя' : null}
        </span>

        <input
          placeholder="Введите email"
          className={cn('Input', { Input__error: errors.email })}
          disabled={disabled}
          {...register('email', { required: true, pattern: /^\S+@\S+\.\S+$/ })}
        />
        <span className="fs-secondary Input__error_subtitle">
          {errors.email && errors.email.type === 'required' ? 'Введите email' : null}
          {errors.email && errors.email.type === 'pattern' ? 'Введите корректный email' : null}
        </span>

        <Button type="submit" className="Form__submit" disabled={disabled} onClick={YMSale}>
          {disabled ? <BarLoader color="#ebe5d7" /> : 'Получить скидку'}
        </Button>

        <label className="Checkbox" htmlFor="personal_data">
          <input
            id="personal_data"
            type="checkbox"
            disabled={disabled}
            {...register('personal_data', { required: true })}
          />
          <span
            className={cn('Checkbox__mark', {
              Checkbox__mark_marked: watch('personal_data'),
              Checkbox__mark_error: errors.personal_data,
              Checkbox__mark_disabled: disabled,
            })}
          />
          Я даю согласие на
          <a
            href="https://secretmusical.ru/documents/politika_obrabotki_personalnyh_dannyh.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: '.2em', color: '#8471d0' }}
            onClick={YMPersonnalData}
          >
            обработку персональных данных
          </a>
          .
        </label>

        <label className="Checkbox">
          <input
            id="commercials"
            type="checkbox"
            disabled={disabled}
            {...register('commercials', { required: true })}
          />
          <span
            className={cn('Checkbox__mark', {
              Checkbox__mark_marked: watch('commercials'),
              Checkbox__mark_error: errors.commercials,
              Checkbox__mark_disabled: disabled,
            })}
          />
          Я даю согласие на получение рекламных сообщений.
        </label>
      </form>
    </>
  );
};

export default Form;
