import useGlobalStore from 'useGlobalStore';
import './WelcomeScreen.scss';
import logo from 'shared/assets/images/logo.png';
import titleBg from 'shared/assets/images/WelcomeTitleBG.png';
import { Button } from 'shared/ui/Button/Button';
import { YMGameStart } from 'shared/api/YandexMetrikaFunctions';

const WelcomeScreen = () => {
  const startPlaying = useGlobalStore((state) => state.startPlaying);
  const calcDimensions = useGlobalStore((state) => state.calcDimensions);

  const start = () => {
    YMGameStart();
    startPlaying();
    calcDimensions();
  };

  return (
    <div className="WelcomeScreen">
      <div className="WelcomeScreen__stars"></div>
      <section className="WelcomeScreen__section">
        <div className="WelcomeScreen__section__logo">
          <img src={logo} alt="Мюзикл Ничего не бойся, я с тобой" />
        </div>

        <h1 className="WelcomeScreen__title">
          <span className="WelcomeScreen__title_upper">Летим за мечтой</span>
          <span className="WelcomeScreen__title_lower">и подарками</span>
          <img src={titleBg} alt="фон заголовка" className="WelcomeScreen__title_image" />
        </h1>

        <p className="WelcomeScreen__subtitle">
          Помогите Славе и Алисе облететь Ленинград на&nbsp;троллейбусе. Соберите как можно больше
          пластинок и&nbsp;букетов, чтобы получить подарок за игру
        </p>

        <Button onClick={start} size="large">
          Играть
        </Button>

        <div className="WelcomeScreen__rules">
          <p className="fs-subtitle WelcomeScreen__rules_title">Как играть?</p>
          <ol className="fs-subtitle WelcomeScreen__rules_list">
            <li>
              <span className="WelcomeScreen__rules_number">1</span>
              <span className="fs-main">
                <span className="ds-visible">
                  Нажмите пробел или левую кнопку мыши, чтобы поднять троллейбус.
                </span>
                <span className="m-visible">Нажмите на экран, чтобы взлететь.</span> Чем&nbsp;больше
                нажатий подряд, тем выше взлетит троллейбус, и наоборот.
              </span>
            </li>
            <li>
              <span className="WelcomeScreen__rules_number">2</span>
              <span className="fs-main">
                По&nbsp;пути вам будут встречаться пластинки и букеты — это &laquo;хорошие&raquo;
                предметы. Собирайте их и получайте баллы.
              </span>
            </li>
            <li>
              <span className="WelcomeScreen__rules_number">3</span>
              <span className="fs-main">
                Столкнетесь с&nbsp;крышей, мостом, шпилем, птицей или газетой — игра закончится.
              </span>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
};

export default WelcomeScreen;
