import GameWrapper from 'app/Game';
import WelcomeScreen from 'app/components/WelcomeScreen';
import useGlobalStore from 'useGlobalStore';

const GameLayer = () => {
  const isPlaying = useGlobalStore((state) => state.isPlaying);
  const crashedInto = useGlobalStore((state) => state.crashedInto);
  return (
    <>
      <GameWrapper />
      {isPlaying || crashedInto ? null : <WelcomeScreen />}
    </>
  );
};

export default GameLayer;
