import image from 'shared/assets/images/Building5.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building5 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 1685 * dimensions.coef,
    height: 444 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 1500 * dimensions.coef,
      height: 124 * dimensions.coef,
      left: x + 90 * dimensions.coef,
      top: y + 320 * dimensions.coef,
    },
    {
      width: 1100 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 290 * dimensions.coef,
      top: y + 280 * dimensions.coef,
    },
    {
      width: 980 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 350 * dimensions.coef,
      top: y + 230 * dimensions.coef,
    },
    {
      width: 100 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 410 * dimensions.coef,
      top: y + 180 * dimensions.coef,
    },
    {
      width: 100 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 1170 * dimensions.coef,
      top: y + 180 * dimensions.coef,
    },
    {
      width: 100 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 470 * dimensions.coef,
      top: y + 130 * dimensions.coef,
    },
    {
      width: 100 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 1110 * dimensions.coef,
      top: y + 130 * dimensions.coef,
    },
    {
      width: 50 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 530 * dimensions.coef,
      top: y + 80 * dimensions.coef,
    },
    {
      width: 50 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 1100 * dimensions.coef,
      top: y + 80 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building5;
