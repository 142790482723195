import image from 'shared/assets/images/Building6.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building6 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 457 * dimensions.coef,
    height: 214 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 443 * dimensions.coef,
      height: 160 * dimensions.coef,
      left: x + 7 * dimensions.coef,
      top: y + 54 * dimensions.coef,
    },
    {
      width: 153 * dimensions.coef,
      height: 34 * dimensions.coef,
      left: x + 153 * dimensions.coef,
      top: y + 20 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building6;
