import React from 'react';
import App from 'app';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

const domNode = document.getElementById('root');
const root = createRoot(domNode!);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
