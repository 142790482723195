import { createPortal } from 'react-dom';
import MusicPlayer from 'shared/ui/MusicPlayer';
import RulesLink from 'shared/ui/RulesLink';

const MusicAndRulesLayer = () => {
  return createPortal(
    <>
      <RulesLink />
      <MusicPlayer />
    </>,
    document.getElementById('music-rules')!
  );
};

export default MusicAndRulesLayer;
