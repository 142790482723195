import { useEffect, useRef } from 'react';

const useSpeedMultiplier = () => {
  const sm = useRef<number>(5);

  const increaseSM = () => (sm.current += 0.01);

  const resetSM = () => (sm.current = 5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sm.current >= 15) {
        clearInterval(interval);
        return;
      } else {
        increaseSM();
      }
    }, 200);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sm.current]);

  return { speedMultiplier: sm.current, resetSpeedMultiplier: resetSM };
};

export default useSpeedMultiplier;
