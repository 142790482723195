import image from 'shared/assets/images/Flowers.png';
import useCreateFriend from 'app/Game/hooks/useCreateFriend';
import { IDimensions } from 'types';

const Flowers = (
  dimensions: IDimensions,
  startX: number,
  springDuration: number,
  incPoints: () => void
) => {
  const imageProperties = {
    width: 94 * dimensions.coef,
    height: 134 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 60 * dimensions.coef,
      height: 100 * dimensions.coef,
      left: x + 17 * dimensions.coef,
      top: y + 17 * dimensions.coef,
    },
  ];

  return useCreateFriend({
    imageProperties,
    collisionBlocks,
    image,
    dimensions,
    startX,
    springDuration,
    incPoints,
  });
};

export default Flowers;
