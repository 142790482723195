import LoadingScreen from 'app/components/LoadingScreen';
import ResolutionChangedScreen from 'app/components/ResolutionChangedScreen';
import { createPortal } from 'react-dom';
import useIsLandscape from 'shared/hooks/useIsLandscape';
import useIsLoading from 'shared/hooks/useIsLoading';
import useGlobalStore from 'useGlobalStore';

const ResolutionChangedAndLoadingLayer = () => {
  const isResolutionChanged = useGlobalStore((state) => state.isResolutionChanged);
  const isPlaying = useGlobalStore((state) => state.isPlaying);
  const isLandscape = useIsLandscape();
  const isLoading = useIsLoading();

  return createPortal(
    <>
      <LoadingScreen isOpen={isLoading} />
      <ResolutionChangedScreen isOpen={!isLoading && isLandscape}>
        <p className="fs-subtitle">Переверните экран, чтобы продолжить игру</p>
      </ResolutionChangedScreen>
      <ResolutionChangedScreen isOpen={!isLoading && isResolutionChanged && isPlaying}>
        <p className="fs-subtitle">Вы изменили настройки экрана.</p>
        <p className="fs-subtitle">Вернитесь к предыдущим размерам экрана, чтобы продолжить игру</p>
      </ResolutionChangedScreen>
    </>,
    document.getElementById('resolutionchanged-loading')!
  );
};

export default ResolutionChangedAndLoadingLayer;
