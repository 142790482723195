import { useEffect, useState } from 'react';
import { Rectangle } from 'pixi.js';
import '@pixi/events';
import { Container, useTick } from '@pixi/react';
import useSound from 'use-sound';
import useGlobalStore from 'useGlobalStore';
import failSound from 'shared/assets/sounds/effects/Fail.wav';
import goodSound from 'shared/assets/sounds/effects/Good.wav';
import checkOutOfBounds from './functions/checkOutOfBounds';
import Building1 from './components/enemies/Building1';
import Building2 from './components/enemies/Building2';
import Building3 from './components/enemies/Building3';
import Building4 from './components/enemies/Building4';
import Building5 from './components/enemies/Building5';
import Building6 from './components/enemies/Building6';
import Building7 from './components/enemies/Building7';
import Building8 from './components/enemies/Building8';
import Newspaper from './components/enemies/Newspaper';
import Seagull from './components/enemies/Seagull';
import Flowers from './components/friends/Flowers';
import Vinyl from './components/friends/Vinyl';
import Background from './components/background';
import Protagonist from './components/protagonist';
import getRandomInt from './functions/getRandomInt';
import PointCounter from './components/pointCounter';
import LogoSprite from './components/logo';
import useSpeedMultiplier from './hooks/useSpeedMultiplier';
import { IDimensions, TCrashedInto } from 'types';
import useIsLandscape from 'shared/hooks/useIsLandscape';

const Game = ({ dimensions }: { dimensions: IDimensions }) => {
  const isPlaying = useGlobalStore((state) => state.isPlaying);
  const stopPlaying = useGlobalStore((state) => state.stopPlaying);
  const setCrashedInto = useGlobalStore((state) => state.setCrashedInto);
  const setPointsCount = useGlobalStore((state) => state.setPointsCount);
  const isResolutionChanged = useGlobalStore((state) => state.isResolutionChanged);
  const newResolution = useGlobalStore((state) => state.newResolution);
  const oldResolution = useGlobalStore((state) => state.oldResolution);
  const isLandscape = useIsLandscape();

  const [playFailSound] = useSound(failSound);
  const [playGoodSound] = useSound(goodSound);

  const [points, setPoints] = useState(0);
  const incPoints = () => setPoints((prev) => prev + 1);
  const [isPaused, setIsPaused] = useState(false);

  const { speedMultiplier, resetSpeedMultiplier } = useSpeedMultiplier();

  const protagonist = Protagonist(dimensions);

  const b1 = Building1(dimensions, 0);
  const b2 = Building6(dimensions, 596);
  const b3 = Building3(dimensions, 1073);
  const b4 = Building7(dimensions, 1306);
  const b5 = Building5(dimensions, 2014);
  const b6 = Building2(dimensions, 3719);
  const b7 = Building8(dimensions, 4324);
  const b8 = Building4(dimensions, 4984);

  const e1 = Newspaper(
    dimensions,
    getRandomInt(
      dimensions.generateXDistance + 50 * dimensions.coef,
      1.5 * dimensions.generateXDistance - 50 * dimensions.coef
    )
  );
  const e2 = Seagull(
    dimensions,
    getRandomInt(
      1.5 * dimensions.generateXDistance + 50 * dimensions.coef,
      2 * dimensions.generateXDistance + 50 * dimensions.coef
    )
  );

  const f1 = Flowers(
    dimensions,
    getRandomInt(
      dimensions.generateXDistance + 50 * dimensions.coef,
      1.5 * dimensions.generateXDistance - 50 * dimensions.coef
    ),
    300,
    incPoints
  );
  const f2 = Vinyl(
    dimensions,
    getRandomInt(
      1.5 * dimensions.generateXDistance + 50 * dimensions.coef,
      2 * dimensions.generateXDistance + 50 * dimensions.coef
    ),
    600,
    incPoints
  );

  const bg1 = Background(dimensions, 0);
  const bg2 = Background(dimensions, 1);

  const [timeSinceSpacePressed, setTimeSinceSpacePressed] = useState(0);

  const stop = (newCrashedInto: TCrashedInto) => {
    playFailSound();
    stopPlaying();
    setCrashedInto(newCrashedInto);
    setPointsCount(points);
  };

  const resetAll = () => {
    setTimeSinceSpacePressed(0);

    b1.resetX();
    b2.resetX();
    b3.resetX();
    b4.resetX();
    b5.resetX();
    b6.resetX();
    b7.resetX();
    b8.resetX();

    e1.reset();
    e2.reset();

    f1.reset();
    f2.reset();

    protagonist.setY(dimensions.height / 2);
    setTimeSinceSpacePressed(0);

    setPoints(0);
    resetSpeedMultiplier();
  };

  const spacePress = () => {
    if (isPlaying) {
      setTimeSinceSpacePressed(0);
    }
  };

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      e.key === ' ' && spacePress();
    };

    const keyUpHandler = (e: KeyboardEvent) => {};

    document.addEventListener('keydown', keyDownHandler, false);
    document.addEventListener('keyup', keyUpHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
      document.removeEventListener('keyup', keyUpHandler, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      resetAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useTick((delta) => {
    if (isPlaying && !isPaused && !isLandscape) {
      bg1.updateX(delta);
      bg2.updateX(delta);

      const newTimeSinceSpacePressed = timeSinceSpacePressed + 0.1 * delta;
      setTimeSinceSpacePressed(newTimeSinceSpacePressed);

      protagonist.updateY(timeSinceSpacePressed);
      const protagonistCollision = protagonist.collisionBlock();

      const isOutOfBounds = checkOutOfBounds(
        { y: protagonist.y, height: protagonistCollision.height },
        0,
        dimensions.height
      );
      if (isOutOfBounds) {
        stop('sky_or_ground');
        return;
      }

      const { isCollide: isCollideB1 } = b1.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB1) {
        stop('spire');
        return;
      }

      const { isCollide: isCollideB2 } = b2.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB2) {
        stop('building');
        return;
      }

      const { isCollide: isCollideB3 } = b3.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB3) {
        stop('spire');
        return;
      }

      const { isCollide: isCollideB4 } = b4.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB4) {
        stop('building');
        return;
      }

      const { isCollide: isCollideB5 } = b5.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB5) {
        stop('bridge');
        return;
      }

      const { isCollide: isCollideB6 } = b6.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB6) {
        stop('building');
        return;
      }

      const { isCollide: isCollideB7 } = b7.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB7) {
        stop('building');
        return;
      }

      const { isCollide: isCollideB8 } = b8.updateX(delta, protagonistCollision, speedMultiplier);
      if (isCollideB8) {
        stop('building');
        return;
      }

      const { isCollide: isCollideE1 } = e1.update(
        delta,
        protagonistCollision,
        speedMultiplier,
        e2.x > dimensions.generateXDistance ? e2.x : dimensions.generateXDistance
      );
      if (isCollideE1) {
        stop('newspaper');
        return;
      }

      const { isCollide: isCollideE2 } = e2.update(
        delta,
        protagonistCollision,
        speedMultiplier,
        e1.x > dimensions.generateXDistance ? e1.x : dimensions.generateXDistance
      );
      if (isCollideE2) {
        stop('seagul');
        return;
      }

      f1.update(
        delta,
        protagonistCollision,
        speedMultiplier,
        playGoodSound,
        f2.x > dimensions.generateXDistance ? f2.x : dimensions.generateXDistance
      );
      f2.update(
        delta,
        protagonistCollision,
        speedMultiplier,
        playGoodSound,
        f1.x > dimensions.generateXDistance ? f1.x : dimensions.generateXDistance
      );
    }

    if (dimensions.width !== window.innerWidth || dimensions.height !== window.innerHeight) {
      if (!isResolutionChanged) {
        newResolution();
        setIsPaused(true);
      }
    } else {
      if (isResolutionChanged) {
        oldResolution();
        setIsPaused(false);
      }
    }
  });

  return (
    <Container
      x={0}
      y={0}
      onpointerdown={spacePress}
      interactive
      hitArea={new Rectangle(0, 0, dimensions.width, dimensions.height)}
    >
      <bg1.component />
      <bg2.component />
      <LogoSprite dimensions={dimensions} />
      <PointCounter points={points} dimensions={dimensions} />
      {/* это автобус */}
      <protagonist.component />
      {/* Это враг */}
      <b1.component />
      <b2.component />
      <b3.component />
      <b4.component />
      <b5.component />
      <b6.component />
      <b7.component />
      <b8.component />
      <e1.component />
      <e2.component />
      {/* Это друг */}
      <f1.component />
      <f2.component />
    </Container>
  );
};

export default Game;
