import useGlobalStore from 'useGlobalStore';
import { Button } from 'shared/ui/Button/Button';
import { ReactComponent as IconTg } from 'shared/assets/images/tg.svg';
import { ReactComponent as IconViber } from 'shared/assets/images/viber.svg';
import { ReactComponent as IconVk } from 'shared/assets/images/vk.svg';
import { ReactComponent as IconWhatsapp } from 'shared/assets/images/whatsapp.svg';
import birds from 'shared/assets/images/birds.png';
import nothing_disapears from 'shared/assets/images/nothing_disappears.png';
import './FormSent.scss';
import {
  TelegramShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  YMGameReplay2,
  YMGameReplay3,
  YMTg,
  YMViber,
  YMVK,
  YMWhatsapp,
} from 'shared/api/YandexMetrikaFunctions';

const variants = {
  new: {
    title: 'Спасибо!',
    subtitle: 'Мы отправим вам промокод по электронной почте',
  },
  old: {
    title: 'Вы уже получили свой промокод!',
    subtitle: 'Ищите его на почте',
  },
  error: {
    title: 'Произошла ошибка',
    subtitle: 'Повторите позднее',
  },
};

const FormSent = ({ variant }: { variant: 'old' | 'new' | 'error' }) => {
  const startPlaying = useGlobalStore((state) => state.startPlaying);
  const calcDimensions = useGlobalStore((state) => state.calcDimensions);
  const { title, subtitle } = variants[variant];

  const url = process.env.REACT_APP_PUBLIC_URL || '';

  const replay = () => {
    variant === 'new' && YMGameReplay2();
    variant === 'old' && YMGameReplay3();
    startPlaying();
    calcDimensions();
  };

  return (
    <>
      <div className="FormSent">
        <p className="fs-title FormSent__title">{title}</p>
        <p className="fs-subtitle FormSent__subtitle">{subtitle}</p>
        <Button onClick={replay} type="button">
          Играть еще раз
        </Button>
        <div className="FormSent__share">
          <p className="fs-main FormSent__share_title">Поделитесь игрой с друзьями</p>
          <ul className="FormSent__share_list">
            <li>
              <TelegramShareButton
                className="FormSent__share_list_icon"
                url={url}
                title="Совершите побег из реальности в Питер 80-х в игре по мотивам мюзикла «Ничего не бойся, я с тобой». Играйте и получайте гарантированные подарки."
                onClick={YMTg}
              >
                <IconTg className="FormSent__share_list_icon_tg" />
              </TelegramShareButton>
            </li>
            <li>
              <VKShareButton
                className="FormSent__share_list_icon"
                url={url}
                title="Играйте и получайте призы."
                image={`${url}/og_image.png`}
                onClick={YMVK}
              >
                <IconVk className="FormSent__share_list_icon_vk" />
              </VKShareButton>
            </li>
            <li>
              <WhatsappShareButton
                className="FormSent__share_list_icon"
                url={url}
                title="Совершите побег из реальности в Питер 80-х в игре по мотивам мюзикла «Ничего не бойся, я с тобой». Играйте и получайте гарантированные подарки."
                separator=" "
                onClick={YMWhatsapp}
              >
                <IconWhatsapp className="FormSent__share_list_icon_whatsapp" />
              </WhatsappShareButton>
            </li>
            <li>
              <ViberShareButton
                className="FormSent__share_list_icon"
                url={url}
                title="Совершите побег из реальности в Питер 80-х в игре по мотивам мюзикла «Ничего не бойся, я с тобой». Играйте и получайте гарантированные подарки."
                separator=" "
                onClick={YMViber}
              >
                <IconViber className="FormSent__share_list_icon_viber" />
              </ViberShareButton>
            </li>
          </ul>
        </div>
      </div>
      <img src={birds} alt="Птицы" className="FormSentImages__birds" />
      {variant === 'old' ? (
        <img
          src={nothing_disapears}
          alt="Ничего не исчезает"
          className="FormSentImages__nothing_disapears"
        />
      ) : null}
    </>
  );
};

export default FormSent;
