import useCreateFriend from 'app/Game/hooks/useCreateFriend';
import image from 'shared/assets/images/Vinyl.png';
import { IDimensions } from 'types';

const Vinyl = (
  dimensions: IDimensions,
  startX: number,
  springDuration: number,
  incPoints: () => void
) => {
  const imageProperties = {
    width: 136 * dimensions.coef,
    height: 139 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 85 * dimensions.coef,
      height: 85 * dimensions.coef,
      left: x + 26 * dimensions.coef,
      top: y + 27 * dimensions.coef,
    },
  ];

  return useCreateFriend({
    imageProperties,
    collisionBlocks,
    image,
    dimensions,
    startX,
    springDuration,
    incPoints,
  });
};

export default Vinyl;
