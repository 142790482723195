import CountUp from 'react-countup';
import BarLoader from 'react-spinners/BarLoader';
import CSSTransition from 'react-transition-group/CSSTransition';
import './LoadingScreen.scss';

const LoadingScreen = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <CSSTransition in={isOpen} timeout={300} classNames="Loading" unmountOnExit>
      <div className="Loading__backdrop">
        <div className="Loading__content">
          <BarLoader color="#ffffff" height={10} width={200} speedMultiplier={0.135} />
          <p>
            <CountUp end={99} duration={4.5} />%
          </p>
        </div>
      </div>
    </CSSTransition>
  );
};

export default LoadingScreen;
