declare global {
  interface Window {
    ym: any;
  }
}

const YANDEX_METRIKA_ID = 97401671;

// Играть
export const YMGameStart = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'GameStart');

// Играть еще раз
export const YMGameReplay = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'GameReplay');

// Получить скидку
export const YMSale = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'Sale');

// Кнопка закрытия
export const YMClose = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'Close');

// Играть еще раз после данных
export const YMGameReplay2 = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'GameReplay2');

// Играть еще раз после данных 2-3 раза
export const YMGameReplay3 = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'GameReplay3');

// Tg
export const YMTg = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'Tg');

// VK
export const YMVK = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'VK');

// Whatsapp
export const YMWhatsapp = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'Whatsapp');

// Viber
export const YMViber = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'Viber');

// Полные правила акции
export const YMFullRules = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'FullRules');

// Условия обработки ПД
export const YMPersonnalData = () => window.ym(YANDEX_METRIKA_ID, 'reachGoal', 'PersonnalData');
