import { YMFullRules } from 'shared/api/YandexMetrikaFunctions';
import './RulesLink.scss';

const RulesLink = () => {
  return (
    <a
      href="https://secretmusical.ru/upload/pravila-provedeniya-meropriyatiya.pdf"
      target="_blank"
      rel="noreferrer"
      className="fs-small RulesLink"
      onClick={YMFullRules}
    >
      Полные правила акции
    </a>
  );
};

export default RulesLink;
