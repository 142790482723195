import image from 'shared/assets/images/Building7.png';
import useCreateBuilding from '../../../hooks/useCreateBuilding';
import { IDimensions } from 'types';

const Building7 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 688 * dimensions.coef,
    height: 344 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 668 * dimensions.coef,
      height: 270 * dimensions.coef,
      left: x + 10 * dimensions.coef,
      top: y + 74 * dimensions.coef,
    },
    {
      width: 468 * dimensions.coef,
      height: 50 * dimensions.coef,
      left: x + 110 * dimensions.coef,
      top: y + 24 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building7;
