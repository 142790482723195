import './ManyPoints.scss';
import { useState } from 'react';
import Form, { TInputs } from './Form';
import FormSent from './FormSent';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';

const ManyPoints = () => {
  const [userVariant, setUserVariant] = useState<'new' | 'old' | null | 'error'>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (formData: TInputs) => {
      return await axios
        .post(
          `${process.env.REACT_APP_STRAPI_URL}/players`,
          { data: formData },
          {
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
            },
          }
        )
        .catch((err: AxiosError) => {
          throw err;
        });
    },
    onSuccess: (resp) => {
      if (resp.status === 200) {
        setUserVariant('new');
        return;
      }
    },
    onError: (err: AxiosError<{ error: { name: string } }>) => {
      if (err.response?.status === 400 && err.response?.data?.error.name === 'ValidationError') {
        setUserVariant('old');
        return;
      }

      setUserVariant('error');
    },
  });

  if (userVariant) return <FormSent variant={userVariant} />;

  return <Form onSubmit={mutate} disabled={isLoading} />;
};

export default ManyPoints;
