import { useRef, useState } from 'react';
import music from 'shared/assets/sounds/music.wav';
import { ReactComponent as SvgOn } from 'shared/assets/images/music_on.svg';
import { ReactComponent as SvgOff } from 'shared/assets/images/music_off.svg';
import useGlobalStore from 'useGlobalStore';
import './MusicPlayer.scss';

const MusicPlayer = () => {
  const isGamePlaying = useGlobalStore((state) => state.isPlaying);
  const crashedInto = useGlobalStore((state) => state.crashedInto);
  const [isPlaying, setIsPlaying] = useState(false);
  const backgroundMusic = useRef(new Audio(music));
  backgroundMusic.current.loop = true;

  const stopMusic = () => {
    backgroundMusic.current.pause();
    backgroundMusic.current.currentTime = 0;
  };

  const playMusic = () => {
    backgroundMusic.current.play();
  };

  if (!!crashedInto && !isGamePlaying) {
    stopMusic();
  }

  if (!!crashedInto && isGamePlaying && isPlaying) {
    playMusic();
  }

  const onClick = () => {
    if (isPlaying) {
      stopMusic();
      setIsPlaying(false);
    } else {
      playMusic();
      setIsPlaying(true);
    }
  };

  return (
    <button className="MusicPlayer" onClick={onClick}>
      {isPlaying ? <SvgOn /> : <SvgOff />}
    </button>
  );
};

export default MusicPlayer;
