import { useEffect } from 'react';
import { Sprite } from '@pixi/react';
import { IDimensions } from 'types';
import useGameObj from '../../hooks/useGameObj';
import image from 'shared/assets/images/bg.png';

const Background = (dimensions: IDimensions, index: number) => {
  const hCoef = dimensions.height / 820;

  const imageProperties = {
    width: 3666 * hCoef,
    height: 820 * hCoef,
  };

  const properties = useGameObj({
    x: imageProperties.width * index,
    y: 0,
  });

  useEffect(() => {
    properties.setX(imageProperties.width * index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  const updateX = (delta: number) => {
    const newX =
      properties.x > -imageProperties.width ? properties.x - delta * 2 : imageProperties.width;
    properties.setX(newX);
  };

  return {
    component: () => <Sprite image={image} x={properties.x} y={0} {...imageProperties} />,
    updateX,
  };
};

export default Background;
