import { Sprite } from '@pixi/react';
import image from 'shared/assets/images/logo.png';
import { IDimensions } from 'types';

const logoProperties = {
  desktop: {
    width: 186,
    height: 99,
    x: 60,
    y: 30,
  },
  tablet: {
    width: 186,
    height: 99,
    x: 40,
    y: 30,
  },
  mobile: {
    width: 100,
    height: 53,
    x: 20,
    y: 20,
  },
};

const LogoSprite = ({ dimensions }: { dimensions: IDimensions }) => (
  <Sprite image={image} {...logoProperties[dimensions.screenSize]} />
);

export default LogoSprite;
