import useGlobalStore from 'useGlobalStore';
import seagul from 'shared/assets/images/crash_seagul.png';
import newspaper from 'shared/assets/images/crash_newspaper.png';
import building from 'shared/assets/images/crash_building.png';
import spire from 'shared/assets/images/crash_spire.png';
import bridge from 'shared/assets/images/crash_bridge.png';
import './ZeroPoints.scss';
import { Button } from 'shared/ui/Button/Button';
import { YMGameReplay } from 'shared/api/YandexMetrikaFunctions';

const crashedIntoVariants: {
  [k: string]: {
    text: string;
    image?: string;
    button_text?: string;
  };
} = {
  seagul: {
    text: 'Фонарь луны давно погас, и, кажется, вы не заметили чайку. Давайте попробуем сначала?',
    image: seagul,
  },
  newspaper: {
    text: 'Почитать «Ленинградский забой» было бы очень интересно. Но наша цель — ты-ты-ты-тысяча пластинок. Попробуйте заново.',
    image: newspaper,
  },
  bridge: {
    text: ' Дворы пусты, свели мосты, но приземляться на них не рекомендуем. Попробуйте заново.',
    image: bridge,
  },
  spire: {
    text: 'Ничего не исчезает. Даже если жизнь кидает нас на вираже. Вот и вы не заметили шпиль. Попробуйте новый маршрут.',
    image: spire,
  },
  building: {
    text: 'Я, сидя на крыше, не ниже, не выше… Но тут троллейбус лучше не парковать. Попробуйте пролететь как можно дальше.',
    image: building,
  },
  sky_or_ground: {
    text: 'Вы покинули воздушное пространство Ленинграда!',
    button_text: 'Снова в полёт',
  },
};

const ZeroPoints = () => {
  const crashedInto = useGlobalStore((state) => state.crashedInto);
  const startPlaying = useGlobalStore((state) => state.startPlaying);
  const calcDimensions = useGlobalStore((state) => state.calcDimensions);

  if (!crashedInto) return <></>;

  const { text, image, button_text } = crashedIntoVariants[crashedInto];

  const replay = () => {
    YMGameReplay();
    startPlaying();
    calcDimensions();
  };

  return (
    <>
      <p className="fs-title ZeroPoints__title">Было? Не было!</p>
      <p className="fs-subtitle ZeroPoints__subtitle">А может, все-таки было?</p>
      {image ? <img src={image} alt={text} className="ZeroPoints__image" /> : null}
      <p className="fs-main ZeroPoints__text">{text}</p>
      <Button onClick={replay} type="button" className="ZeroPoints__button">
        {button_text ? button_text : 'Играть еще раз'}
      </Button>
    </>
  );
};

export default ZeroPoints;
