export type TCollisionObject = {
  width: number;
  height: number;
  left: number;
  top: number;
};

const checkCollision = (bounds1: TCollisionObject, bounds2: TCollisionObject) =>
  bounds1.left < bounds2.left + bounds2.width &&
  bounds1.left + bounds1.width > bounds2.left &&
  bounds1.top < bounds2.top + bounds2.height &&
  bounds1.top + bounds1.height > bounds2.top;

export default checkCollision;
