import { Sprite, Text } from '@pixi/react';
import { TextStyle } from '@pixi/text';
import image from 'shared/assets/images/PointCounterBG.png';
import { IDimensions } from 'types';

const pointCounterProperties = (screenWidth: number) => ({
  desktop: {
    width: 191,
    height: 74,
    x: screenWidth - 211,
    y: 23,
    fontSize: 40,
  },
  tablet: {
    width: 191,
    height: 74,
    x: screenWidth - 208,
    y: 23,
    fontSize: 40,
  },
  mobile: {
    width: 133,
    height: 50,
    x: screenWidth - 139,
    y: 19,
    fontSize: 24,
  },
});

const PointCounter = ({
  points,
  dimensions,
}: {
  points: number | string;
  dimensions: IDimensions;
}) => {
  const properties = pointCounterProperties(dimensions.width)[dimensions.screenSize];
  return (
    <>
      <Sprite
        image={image}
        x={properties.x}
        y={properties.y}
        width={properties.width}
        height={properties.height}
      />
      <Text
        text={points.toString()}
        x={properties.x + properties.width / 2}
        y={properties.y + properties.height / 2}
        style={
          new TextStyle({
            // TODO заменить на Fira Sans Extra Condensed
            fontFamily: 'Arial',
            fontSize: properties.fontSize,
            fontWeight: 'bold',
          })
        }
        anchor={0.5}
      />
    </>
  );
};

export default PointCounter;
