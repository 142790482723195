import image from 'shared/assets/images/Building8.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building8 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 640 * dimensions.coef,
    height: 324 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 590 * dimensions.coef,
      height: 275 * dimensions.coef,
      left: x + 25 * dimensions.coef,
      top: y + 49 * dimensions.coef,
    },
    {
      width: 300 * dimensions.coef,
      height: 275 * dimensions.coef,
      left: x + 170 * dimensions.coef,
      top: y + 29 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building8;
