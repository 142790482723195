import { useCallback } from 'react';
import { useSpring } from 'react-spring';

const useSpringOneToZero = (duration: number) => {
  const [spring, setSpring] = useSpring(() => ({
    value: 1,
    config: { duration },
  }));

  const doSpring = useCallback(() => {
    setSpring({ value: 0 });
    setTimeout(() => {
      setSpring({ value: 1 });
    }, duration);
  }, [setSpring, duration]);

  return { value: spring.value, doSpring, duration };
};

export default useSpringOneToZero;
