import { useState } from 'react';
import { animated } from 'react-spring';
import { Sprite } from '@pixi/react';
import { PlayFunction } from 'use-sound/dist/types';
import { IDimensions } from 'types';
import useGameObj from '../hooks/useGameObj';
import checkCollision, { TCollisionObject } from '../functions/checkCollision';
import getRandomInt from '../functions/getRandomInt';
import sineWave from '../functions/sineWave';
import useSpringOneToZero from './useSpringOneToZero';

const AnimatedSprite = animated(Sprite);

const useCreateFriend = ({
  imageProperties,
  collisionBlocks,
  image,
  dimensions,
  startX,
  springDuration,
  incPoints,
}: {
  imageProperties: {
    width: number;
    height: number;
  };
  collisionBlocks: ({ x, y }: { x: number; y: number }) => {
    width: number;
    height: number;
    left: number;
    top: number;
  }[];
  image: string;
  dimensions: IDimensions;
  startX: number;
  springDuration: number;
  incPoints: () => void;
}) => {
  const [isCollidedRecently, setIsCollidedRecently] = useState(false);

  const { value: springValue, doSpring } = useSpringOneToZero(springDuration);

  const getRandomY = () =>
    getRandomInt(dimensions.height - imageProperties.height - 390 * dimensions.coef);

  const properties = {
    ...useGameObj({
      x: startX,
      y: getRandomY(),
    }),
    ...imageProperties,
  };

  const update = (
    delta: number,
    protagonistCollision: TCollisionObject,
    speedMultiplier: number,
    playGoodSound: PlayFunction,
    leftBoundary: number
  ) => {
    const newX =
      properties.x > -properties.width
        ? properties.x - delta * speedMultiplier
        : leftBoundary + dimensions.generateXDistance / 2;
    const newYAxis = properties.x > -properties.width ? undefined : getRandomY();
    if (newYAxis) properties.setYAxis(newYAxis);
    const newY = sineWave(newX, newYAxis || properties.yAxis, dimensions.coef);

    if (!isCollidedRecently) {
      const isCollide = collisionBlocks({ x: newX, y: newY })
        .map((col) => checkCollision(protagonistCollision, col))
        .includes(true);

      if (isCollide) {
        doSpring();
        incPoints();
        playGoodSound();

        setIsCollidedRecently(true);
        setTimeout(() => {
          setIsCollidedRecently(false);
        }, springDuration + 100);

        setTimeout(() => {
          properties.setX(leftBoundary + dimensions.generateXDistance / 2);
          properties.setYAxis(getRandomY());
        }, springDuration);
      } else {
        properties.setX(newX);
        if (newY) properties.setY(newY);
      }
    } else {
      properties.setX(newX);
    }
  };

  const reset = () => {
    properties.setYAxis(getRandomY());
    properties.setX(startX);
  };

  return {
    component: () => (
      <AnimatedSprite
        image={image}
        x={properties.x}
        y={properties.y}
        {...imageProperties}
        alpha={springValue}
      />
    ),
    update,
    reset,
    springValue,
    x: properties.x,
  };
};

export default useCreateFriend;
