import image from 'shared/assets/images/Building2.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building2 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 585 * dimensions.coef,
    height: 498 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: 525 * dimensions.coef,
      height: 190 * dimensions.coef,
      left: x + 30 * dimensions.coef,
      top: y + 308 * dimensions.coef,
    },
    {
      width: 305 * dimensions.coef,
      height: 108 * dimensions.coef,
      left: x + 140 * dimensions.coef,
      top: y + 200 * dimensions.coef,
    },
    {
      width: 165 * dimensions.coef,
      height: 110 * dimensions.coef,
      left: x + 210 * dimensions.coef,
      top: y + 100 * dimensions.coef,
    },
    {
      width: 25 * dimensions.coef,
      height: 100 * dimensions.coef,
      left: x + 280 * dimensions.coef,
      top: y,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building2;
