import image from 'shared/assets/images/Building3.png';
import { IDimensions } from 'types';
import useCreateBuilding from '../../../hooks/useCreateBuilding';

const Building3 = (dimensions: IDimensions, xShift: number) => {
  const imageProperties = {
    width: 213 * dimensions.coef,
    height: 509 * dimensions.coef,
  };

  const collisionBlocks = ({ x, y }: { x: number; y: number }) => [
    {
      width: imageProperties.width,
      height: 100 * dimensions.coef,
      left: x,
      top: y + 409 * dimensions.coef,
    },
    {
      width: 81 * dimensions.coef,
      height: 300 * dimensions.coef,
      left: x + 66 * dimensions.coef,
      top: y + 109 * dimensions.coef,
    },
    {
      width: 30 * dimensions.coef,
      height: 60 * dimensions.coef,
      left: x + 92 * dimensions.coef,
      top: y + 40 * dimensions.coef,
    },
  ];

  return useCreateBuilding(imageProperties, collisionBlocks, image, dimensions, xShift);
};

export default Building3;
